import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './BookCoverPreview.module.scss';

const kDefaultImageUrl =
  'https://cdn.autofunnel.ai/cover-preview/cover-placeholder2.png';

interface IProps {
  image: string;
  geometry?: number[];
}

const initializeWebGL = (
  canvas: HTMLCanvasElement
): WebGLRenderingContext | null => {
  const gl = canvas.getContext('webgl');
  if (!gl) {
    console.error('WebGL not supported');
    return null;
  }
  // Clear the color buffer with specified clear color
  gl.clear(gl.COLOR_BUFFER_BIT);

  // Return the WebGL rendering context
  return gl;
};

const BookCoverLayer = (props: IProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const glRef = useRef<WebGLRenderingContext | null>(null);
  const layerRef = useRef<Layer | null>(null);

  function lerp(a: number, b: number, t: number): number {
    return a + (b - a) * t;
  }

  function subdividePolygon(vertices: number[]): number[] {
    const subdivided: number[] = [];
    const count = vertices.length;

    // Add the first vertex as is
    subdivided.push(vertices[0], vertices[1]);
    subdivided.push(vertices[2], vertices[3]);

    const iterations = 15;
    for (let i = 1; i < iterations; i++) {
      const t = i / iterations;

      for (let i = 3; i < vertices.length - 2; i += 2) {
        const x1 = vertices[i - 3];
        const y1 = vertices[i - 2];
        const x2 = vertices[i + 1];
        const y2 = vertices[i + 2];

        const mx = lerp(x1, x2, t);
        const my = lerp(y1, y2, t);

        subdivided.push(mx, my);
      }
    }

    // Add the last vertex as is
    subdivided.push(vertices[count - 4], vertices[count - 3]);
    subdivided.push(vertices[count - 2], vertices[count - 1]);

    return subdivided;
  }
  const subdivideGeometry = (geometry: number[]) => {
    geometry = subdividePolygon(geometry);
    return geometry;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const gl = initializeWebGL(canvas);
      if (gl && props.geometry) {
        glRef.current = gl;
        const subdividedGeometry = subdivideGeometry(props.geometry);
        layerRef.current = new Layer(gl, subdividedGeometry);
        let image;
        if (props.image) {
          image = props.image;
        } else {
          image = kDefaultImageUrl;
        }
        layerRef.current.loadTexture(image).then(() => {
          render();
        });
      }
    }
  }, []);

  useEffect(() => {
    if (layerRef.current && props.image) {
      layerRef.current.loadTexture(props.image).then(() => {
        render();
      });
    }
  }, [props.image]);

  useEffect(() => {
    if (layerRef.current && props.geometry) {
      const subDividedGeometry = subdivideGeometry(props.geometry);
      layerRef.current.setGeometry(subDividedGeometry);
      render();
    }
  }, [props.geometry]);

  const render = () => {
    const gl = glRef.current;
    const canvas = canvasRef.current;
    if (canvas && gl) {
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * window.devicePixelRatio;
      canvas.height = rect.height * window.devicePixelRatio;
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

      if (layerRef.current) {
        layerRef.current.renderImage();
      }
    }
  };

  return (
    <canvas className={styles.canvas} ref={canvasRef} id="canvas"></canvas>
  );
};

export default BookCoverLayer;

// Vertex shader program (handles 2D positioning)
const vertexShaderSource = `
  attribute vec2 a_position;
  attribute vec2 a_texCoord;
  varying vec2 v_texCoord;
  //uniform mat4 u_matrix;

  void main() {
    gl_Position = vec4(a_position, 0, 1);
    v_texCoord = a_texCoord;
  }
`;

// Fragment shader program (handles coloring)
const fragmentShaderSource = `
  precision mediump float;
  varying vec2 v_texCoord;
  uniform sampler2D u_texture;
  void main() {
    gl_FragColor = texture2D(u_texture, v_texCoord);
    
  }
`;

// Compile shaders
function createShader(
  gl: WebGLRenderingContext,
  type: GLenum,
  source: string
): WebGLShader {
  const shader = gl.createShader(type)!;
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
    throw new Error('Shader compilation failed');
  }
  return shader;
}

// Create program and link shaders
function createProgram(
  gl: WebGLRenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader
): WebGLProgram {
  const program = gl.createProgram()!;
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
    gl.deleteProgram(program);
    throw new Error('Program linking failed');
  }
  return program;
}

class Layer {
  private gl: WebGLRenderingContext;

  program: WebGLProgram;
  texture: WebGLTexture | null = null;
  textureLocation: WebGLUniformLocation | null = null;

  vertexBuffer: WebGLBuffer | null = null;
  texCoordBuffer: WebGLBuffer | null = null;

  geometrySize: number = 4;

  constructor(gl: WebGLRenderingContext, geometry: number[]) {
    this.gl = gl;

    //Shaders
    const vertexShader = createShader(
      this.gl,
      this.gl.VERTEX_SHADER,
      vertexShaderSource
    );
    const fragmentShader = createShader(
      this.gl,
      this.gl.FRAGMENT_SHADER,
      fragmentShaderSource
    );
    this.program = createProgram(this.gl, vertexShader, fragmentShader);

    this.initBuffers(geometry);
  }

  private initBuffers(geometry: number[]) {
    // Set up buffer for 2D square
    this.vertexBuffer = this.gl.createBuffer();

    this.setGeometry(geometry);

    // Set up texture coordinates for a full-screen quad
    const texCoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);
    // const texCoords = [0, 0, 1, 0, 0, 1, 1, 1,];
    let texCoords: number[] = [];
    for (let i = 0; i < geometry.length; i += 4) {
      const t = i / (geometry.length - 4);
      texCoords.push(0, t);
      texCoords.push(1, t);
    }

    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array(texCoords),
      this.gl.STATIC_DRAW
    );

    const positionLocation = this.gl.getAttribLocation(
      this.program,
      'a_position'
    );
    // Bind the position buffer and set attributes
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.enableVertexAttribArray(positionLocation);
    this.gl.vertexAttribPointer(
      positionLocation,
      2,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    const texCoordLocation = this.gl.getAttribLocation(
      this.program,
      'a_texCoord'
    );
    this.gl.enableVertexAttribArray(texCoordLocation);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);
    this.gl.vertexAttribPointer(
      texCoordLocation,
      2,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    this.textureLocation = this.gl.getUniformLocation(
      this.program,
      'u_texture'
    );
  }

  setGeometry(geometry: number[]) {
    this.geometrySize = geometry.length / 2;
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);

    let positions: Float32Array = new Float32Array(geometry.length);
    const scale = 512;
    for (let i = 0; i < geometry.length; i += 2) {
      positions.set(
        [
          geometry[i] / scale - 1.0,
          (scale * 2 - geometry[i + 1]) / scale - 1.0,
        ],
        i
      );
    }
    this.gl.bufferData(this.gl.ARRAY_BUFFER, positions, this.gl.STATIC_DRAW);
  }

  loadTexture(url: string): Promise<WebGLTexture> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = url;

      image.onload = () => {
        const texture = this.createTextureFromImage(image);
        this.texture = texture;
        resolve(texture);
      };

      image.onerror = (err) => {
        console.error(`Failed to load image at ${url}:`);
        reject(err);
      };
    });
  }

  private createTextureFromImage(image: HTMLImageElement): WebGLTexture {
    const gl = this.gl;
    const texture = gl.createTexture() as WebGLTexture;
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Set up texture parameters
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    // Unbind the texture
    gl.bindTexture(gl.TEXTURE_2D, null);
    return texture;
  }

  renderImage() {
    this.gl.useProgram(this.program);

    // Bind the texture and draw
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
    this.gl.uniform1i(this.textureLocation, 0);
    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, this.geometrySize);
  }
}
